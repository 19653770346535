import { environment } from '@environment/environment';
import { RxStompConfig } from '@stomp/rx-stomp';
import * as L from 'leaflet';

export class Constants {

  static readonly ad6MapZoomParticipant: number = 15;
  static readonly ad6MarkerColorBlack: string = 'black';
  static readonly ad6MarkerColorBlue: string = 'blue';
  static readonly ad6MarkerColorGreen: string = 'green';
  static readonly ad6MarkerColorGrey: string = 'grey';
  static readonly ad6MarkerColorOrange: string = 'orange';
  static readonly ad6MarkerColorRed: string = 'red';
  static readonly ad6MarkerColorViolet: string = 'violet';
  static readonly ad6MarkerColorYellow: string = 'yellow';
  static readonly ad6MoreMenuCode = 'main';

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static readonly ad6Finish: L.LatLng = new L.LatLng(45.090409, 6.071167);
  static readonly ad6Start: L.LatLng = new L.LatLng(45.060208, 6.037722);
  static readonly ad6Turn01: L.LatLng = new L.LatLng(45.091430, 6.055376);
  static readonly ad6Turn02: L.LatLng = new L.LatLng(45.088681, 6.060461);
  static readonly ad6Turn03: L.LatLng = new L.LatLng(45.088131, 6.055886);
  static readonly ad6Turn04: L.LatLng = new L.LatLng(45.083597, 6.062623);
  static readonly ad6Turn05: L.LatLng = new L.LatLng(45.083689, 6.057534);
  static readonly ad6Turn06: L.LatLng = new L.LatLng(45.082299, 6.062294);
  static readonly ad6Turn07: L.LatLng = new L.LatLng(45.077093, 6.053465);
  static readonly ad6Turn08: L.LatLng = new L.LatLng(45.077900, 6.047794);
  static readonly ad6Turn09: L.LatLng = new L.LatLng(45.074769, 6.052486);
  static readonly ad6Turn10: L.LatLng = new L.LatLng(45.076635, 6.047300);
  static readonly ad6Turn11: L.LatLng = new L.LatLng(45.073209, 6.052097);
  static readonly ad6Turn12: L.LatLng = new L.LatLng(45.074839, 6.047586);
  static readonly ad6Turn13: L.LatLng = new L.LatLng(45.072478, 6.051272);
  static readonly ad6Turn14: L.LatLng = new L.LatLng(45.070923, 6.044278);
  static readonly ad6Turn15: L.LatLng = new L.LatLng(45.071963, 6.048091);
  static readonly ad6Turn16: L.LatLng = new L.LatLng(45.067168, 6.043829);
  static readonly ad6Turn17: L.LatLng = new L.LatLng(45.068953, 6.042846);
  static readonly ad6Turn18: L.LatLng = new L.LatLng(45.065936, 6.043235);
  static readonly ad6Turn19: L.LatLng = new L.LatLng(45.067998, 6.042004);
  static readonly ad6Turn20: L.LatLng = new L.LatLng(45.066428, 6.042194);
  static readonly ad6Turn21: L.LatLng = new L.LatLng(45.071872, 6.039205);

  static readonly appVersionPrefix = `${environment.production ? '' : `${environment.name}.`}AD6.2024`;

  static readonly carouselImages = [
    'assets/images/carroussel1.jpg',
    'assets/images/carroussel2.jpg',
    'assets/images/carroussel3.jpg',
    'assets/images/carroussel4.jpg',
    'assets/images/carroussel5.jpg',
    'assets/images/carroussel6.jpg',
  ];

  static readonly contentPageSize = 10;

  // eslint-disable-next-line @typescript-eslint/member-ordering
  static ad6TimeRegistrations: Map<string, L.LatLng> = new Map(
    [
      ['map.popup.registration.start', Constants.ad6Start],
      ['map.popup.registration.1', Constants.ad6Turn16],
      ['map.popup.registration.2', Constants.ad6Turn09],
      ['map.popup.registration.3', Constants.ad6Turn07],
      ['map.popup.registration.4', Constants.ad6Turn03],
      ['map.popup.registration.finish', Constants.ad6Finish],
    ],
  );

  static readonly ad6Turns: Map<string, L.LatLng> = new Map(
    [
      ['map.popup.start', Constants.ad6Start],
      ['map.popup.turn.21', Constants.ad6Turn21],
      ['map.popup.turn.20', Constants.ad6Turn20],
      ['map.popup.turn.19', Constants.ad6Turn19],
      ['map.popup.turn.18', Constants.ad6Turn18],
      ['map.popup.turn.17', Constants.ad6Turn17],
      ['map.popup.turn.16', Constants.ad6Turn16],
      ['map.popup.turn.15', Constants.ad6Turn15],
      ['map.popup.turn.14', Constants.ad6Turn14],
      ['map.popup.turn.13', Constants.ad6Turn13],
      ['map.popup.turn.12', Constants.ad6Turn12],
      ['map.popup.turn.11', Constants.ad6Turn11],
      ['map.popup.turn.10', Constants.ad6Turn10],
      ['map.popup.turn.09', Constants.ad6Turn09],
      ['map.popup.turn.08', Constants.ad6Turn08],
      ['map.popup.turn.07', Constants.ad6Turn07],
      ['map.popup.turn.06', Constants.ad6Turn06],
      ['map.popup.turn.05', Constants.ad6Turn05],
      ['map.popup.turn.04', Constants.ad6Turn04],
      ['map.popup.turn.03', Constants.ad6Turn03],
      ['map.popup.turn.02', Constants.ad6Turn02],
      ['map.popup.turn.01', Constants.ad6Turn01],
      ['map.popup.finish', Constants.ad6Finish],
    ],
  );

  static readonly dateFormat = 'd MMMM yyyy';
  static readonly dateTimeFormat = 'd MMMM yyyy HH:mm';
  static readonly dismissDelayLoader = 1000;
  static readonly dismissDelayRefresherIcon = 500;
  static readonly dismissDelayToast = 2000;
  static readonly eventCode = 'AD6';
  static readonly eventDate = new Date(2024, 5, 6, 4, 30, 0);
  static readonly eventSettingsUpdateInterval = 1000 * 60 * (environment.production ? 60 : 1);
  static readonly false: string = 'false';
  static readonly oneMinute = 60 * 1000;
  static readonly favoritesUpdateInterval = Constants.oneMinute * (environment.production ? 5 : 1);
  static readonly flickrAlbumOperation: string = 'flickr.photosets.getList';
  static readonly flickrAlbumPhotosOperation: string = 'flickr.photosets.getPhotos';
  static readonly flickrAlbumPhotosPerPage = 15;
  static readonly flickrAlbumsDate = new Date(2023, 5, 1, 0, 0, 0);
  static readonly flickrApiKey: string = '391a2bd7ddae6378eb156d133a0b97b7';
  static readonly flickrUrl: string = 'https://api.flickr.com/services/rest/';
  static readonly flickrUserId: string = '15450269@N03';
  static readonly flussoWebsite: string = 'https://www.flusso.nl';
  static readonly languageCode = 'nl';
  static readonly latestNewsItemCount = 3;
  static readonly participantUrlPrefixDefault: string = 'https://www.opgevenisgeenoptie.nl/fundraisers';
  static readonly searchPageSize = 50;

  static readonly stompConfig: RxStompConfig = {
    // Typically login, passcode and vhost
    // Adjust these for your broker
    // connectHeaders: {
    //   login: "guest",
    //   passcode: "guest"
    // },
    // Broker URL, should start with ws:// or wss:// - adjust for your broker setup
    // eslint-disable-next-line @typescript-eslint/naming-convention
    brokerURL: 'ws://localhost:7081/ws',
    // Keep it off for production, it can be quit verbose
    // Skip this key to disable
    debug: (str: string): void => {
      console.log(`STOMP: ${str}`);
    },
    // If disconnected, it will retry after 500ms
    reconnectDelay: 500,
  };

  static readonly subEventCode = 'alpedhuzes2024';
  static readonly tileLayer: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  static readonly true: string = 'true';
  static readonly urlPrivacy: string = 'https://www.opgevenisgeenoptie.nl/privacy';
  static readonly whatsappUrl: string = 'https://wa.me/';

}
